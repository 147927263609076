<template>
  <a-form :model="formState" label-align="left" layout="vertical">
    <div class="row">
      <button class="btn btn-primary px-5 right-button" @click="saveProfile">
        Save
      </button>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <a-form-item label="Name">
          <a-input v-model:value="formState.name" placeholder="Name" />
        </a-form-item>
      </div>
      <div class="col-lg-12">
        <a-form-item label="Email">
          <a-input
            v-model:value="formState.email"
            placeholder="Email Address"
            readonly
          />
        </a-form-item>
      </div>
      <div class="col-lg-12">
        <a-form-item label="Password">
          <button
            class="btn btn-primary px-5 right-button"
            @click="resetPassword"
          >
            Reset password
          </button>
        </a-form-item>
      </div>
    </div>
    <div class="row">
      <button class="btn btn-primary px-5 right-button" @click="saveProfile">
        Save
      </button>
    </div>
  </a-form>
</template>
<script>
import { InboxOutlined } from "@ant-design/icons-vue";
import { defineComponent, reactive } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  components: {
    InboxOutlined,
  },
  setup() {
    const formState = reactive({
      name: "",
      email: "",
    });
    const store = useStore();
    const user = store.getters["user/user"];

    formState.email = user.email;
    formState.name = user.name;

    return {
      formState,
      user,
      store,
    };
  },
  methods: {
    resetPassword() {
      const that = this;
      this.$confirm({
        title: "Send Password Reset Email?",
        content:
          "Do you want to send a password reset email to this email address (" +
          this.user.email +
          ")?",
        onOk() {
          that.store.dispatch("user/RESET_PASSWORD", {
            email: that.user.email,
          });
        },
        onCancel() {},
      });
    },
    saveProfile() {
      const newProfile = {
        displayName: this.formState.name,
        email: this.formState.email,
      };
      this.store.dispatch("user/SAVE_PROFILE", {
        profile: newProfile,
      });
    },
  },
});
</script>

<style scoped>
.right-button {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: 15px;
}
</style>
