<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <!-- <div class="card-body"><profile /></div> -->
          <div class="card-body"><profileForm /></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Profile from "@/components/Account/Profile/Profile";
import ProfileForm from "@/components/Account/Profile/ProfileForm";
import { computed, reactive } from "vue";
import { useStore } from "vuex";

export default {
  name: "VbProfile",
  components: {
    Profile,
    ProfileForm,
  },
  setup() {
    const store = useStore();
    const user = computed(() => store.getters["user/user"]);
    var isEditable = reactive({ value: false });
    const name = user.value.name;
    const currentOrg = user.value.currentOrg;

    const update = () => {
      isEditable.value = false;
    };

    return { user, name, isEditable, update, currentOrg };
  },
};
</script>
