// @/components/AccountViews/WidgetsGeneral/23v1
<template>
  <div class="py-4 text-center">
    <div
      class="
        vb__utils__avatar vb__utils__avatar--rounded vb__utils__avatar--size84
        border border-5 border-white
        d-inline-block
        mb-3
      "
    >
      <img src="resources/images/avatars/3.jpg" alt="Lee Aniston" />
    </div>
    <div
      class="font-weight-bold font-size-24 text-dark"
      v-if="!isEditable.value"
    >
      {{ user.name }}
    </div>
    <div v-if="isEditable.value">
      <input
        type="text"
        class="font-weight-bold font-size-24 text-dark"
        v-model="user.name"
      />
    </div>
    <div class="font-size-18 text-gray-6 mb-4 mt-n1" v-if="currentOrg.role">
      {{
        currentOrg.role === 0
          ? "Owner"
          : currentOrg.role === 1
          ? "Admin"
          : "Member"
      }}
    </div>
    <!-- <div class="font-size-18 text-gray-6 mb-4 mt-n1" v-if="role===1">Organisation Admin</div>
    <div class="font-size-18 text-gray-6 mb-4 mt-n1" v-if="role===2">Team Member</div> -->
    <edit-button
      :data="{ title: 'Edit', type: 'primary' }"
      @click="isEditable.value = true"
      v-if="!isEditable.value"
    />
    <edit-button
      :data="{ title: 'Update', type: 'primary' }"
      @click="update"
      v-if="isEditable.value"
    />
  </div>
</template>

<script>
import { computed, reactive } from "vue";
import { useStore } from "vuex";
import EditButton from "@/components/Button";
export default {
  name: "VbGeneral23v1",
  components: { EditButton },
  setup() {
    const store = useStore();
    const user = computed(() => store.getters["user/user"]);
    var isEditable = reactive({ value: false });
    const name = user.value.name;
    const currentOrg = user.value.currentOrg;

    const update = () => {
      isEditable.value = false;
    };

    return { user, name, isEditable, update, currentOrg };
  },
};
</script>

<style scoped>
input {
  text-align: center;
  display: inline-block;
}
</style>
